<template>
  <div class="people">
    <EHeader :title="title" :btns="[]" />

    <div class="people-body">
      <div class="user-info" v-if="user">
        <div class="user-avatar">
          <img :src="user.avatar" :alt="user.nickname" />
        </div>
        <div class="user-name">欢迎光临：{{user.nickname}}</div>
      </div>

      <div class="table-people">
        <h2>请问您几人用餐？</h2>
        <p>请选择人数</p>
        <ul v-if="table">
          <li v-for="(v,k) in table.peopleCount" :key="k" @click="onChooseCount(v)" :class="{'on': count === v}">{{v}}</li>
        </ul>
        <a-button class="btn" :disabled="count === 0" type="primary" @click="onSubmit">确定</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from '@ant-design/icons-vue'
import {} from 'ant-design-vue'
import EHeader from '@/components/mobile/Header'
import { TableClass } from '@/apis/table'
import store from '@/store'
import storage from 'store'

const table = new TableClass()
export default defineComponent({
  components: {
    EHeader
  },
  setup () {
    const user = ref()
    const table = ref()
    const title = ref('')
    const count = ref(0)
    return {
      user,
      table,
      title,
      count
    }
  },

  mounted () {
    this.user = store.state.user.userInfo
    this.getTableInfo()

    // const a = decodeURI('https://api-mop.chinaums.com/v1/netpay/webpay/pay?authorization=OPEN-FORM-PARAM&appId=8a81c1bf6f19b2df0170e10e311300d2&timestamp=20220709143435&nonce=58d80af90d244976822fdbe93aa32ea5&content=%7B%22requestTimestamp%22%3A%222022-07-09%2014%3A34%3A35%22,%22merOrderId%22%3A%22700120220709143435436810%22,%22mid%22%3A%22898370348160323%22,%22tid%22%3A%2286223512%22,%22instMid%22%3A%22YUEDANDEFAULT%22,%22originalAmount%22%3A1,%22totalAmount%22%3A1,%22notifyUrl%22%3A%22https%3A%2F%2Fyljy.youlianjiayou.com%2Finterface%2Fapi%2FUnionPay%2FPayNotice%22,%22returnUrl%22%3A%22https%3A%2F%2Fyljy.youlianjiayou.com%2Fpay_return.html%22%7D&signature=yUTHvftjlq3f7xtCN%2FRZ13Ddn%2BcM2xjWTaowc%2BtOi0A%3D')
    // console.log(a)
  },

  methods: {
    async getTableInfo () {
      const id = await this.$store.dispatch('GetScanTableId')
      const count = await this.$store.dispatch('GetScanPeopleCount')
      const storeId = await this.$store.dispatch('GetScanStoreId')
      if (!id || !storeId) {
        this.$message.error('请扫码打开！')
        return
      }

      this.count = count || 0

      table.detail(id).then((resp) => {
        this.table = resp
        this.title = '台位：' + resp.name
      })
    },

    onChooseCount (count) {
      this.count = count
    },

    onSubmit () {
      const id = storage.get('SCAN_TABLE_ID', 0)
      storage.set('SCAN_PEOPLE_COUNT_' + id, this.count)

      this.$router.push({ name: 'scan-index' })
    }
  }
})
</script>

<style lang="less" scoped>
.people{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .people-body{
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #ebe9e9;
    .user-info{
      padding: 0.3rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .user-avatar{
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border: 0.03rem solid #fff;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .user-name{
        font-size: 0.2rem;
        line-height: 0.6rem;
      }
    }

    .table-people{
      flex: 1;
      background: #fff;
      border-radius: 0.2rem 0.2rem 0 0;
      padding-top: 0.2rem;
      h2{
        font-size: 0.3rem;
        text-align: center;
      }
      p{
        font-size: 0.2rem;
        text-align: center;
      }
      ul{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style: none;
        width: 7.2rem;
        margin: 0 auto;
        li{
          border: 1px solid #999898;
          text-align: center;
          width: 1.6rem;
          height: 0.7rem;
          line-height: 0.7rem;
          margin: 0.1rem 0.1rem;
          border-radius: 0.35rem;
          font-size: 0.3rem;
          font-weight: 600;
          &.on{
            background: #7f76fa;
            color: #fff;
          }
        }
      }

      .btn{
        display: block;
        width: 7.2rem;
        height: 0.8rem;
        margin: 0.4rem auto 0;
        border-radius: 0.4rem;
      }
    }
  }
}
</style>
